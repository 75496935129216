import { Locale } from "next/router";

const ZENDESK_BASE_URL = "https://support.whoppah.com";

const LANGUAGE_MAP: Record<Locale, string> = {
  en: "en-gb",
  nl: "nl-nl",
  de: "de",
  fr: "fr",
};

type GetArticleOptions = {
  categoryId?: string;
  sectionId?: string;
  locale: Locale;
};

export type Article = {
  title: string;
  body: string;
};

const zendesk = {
  getUrl: (locale: Locale): string => {
    return `${ZENDESK_BASE_URL}/hc/${LANGUAGE_MAP[locale]}`;
  },
  getArticles: async ({
    categoryId,
    sectionId,
    locale,
  }: GetArticleOptions): Promise<Article[]> => {
    const axios = (await import("axios")).default;
    const { parse } = (await import("node-html-parser")).default;

    const getTextFromHTML = (html: string) => {
      return parse(html).innerText;
    };

    const getFormattedTextFromHTML = (html: string) => {
      const parsedHTML = parse(html);
      const allParagraphs = parsedHTML.querySelectorAll("p");
      for (const paragraph of allParagraphs) {
        // We want paragraphs to inherit the font from parent
        // otherwise they will default to roboto
        paragraph.setAttribute("style", "font-family: inherit");
      }

      const allSpans = parsedHTML.querySelectorAll("span");
      for (const span of allSpans) {
        // Spans returned from zendesk has the following attributes which
        // we don't want to have when rendering so just remove it.
        span.removeAttribute("style");
        span.removeAttribute("data-sheets-value");
        span.removeAttribute("data-sheets-userformat");
        span.removeAttribute("data-sheets-textstyleruns");
      }
      return parsedHTML.innerHTML;
    };

    const urlParts = [
      ZENDESK_BASE_URL,
      "api/v2/help_center",
      LANGUAGE_MAP[locale],
      categoryId ? `categories/${categoryId}` : undefined,
      sectionId ? `sections/${sectionId}` : undefined,
      "articles",
    ].filter(Boolean);
    const response = await axios.get(urlParts.join("/"));
    return response?.data?.articles?.length
      ? response?.data?.articles.map((article: Article) => {
          return {
            title: article.title,
            body: getTextFromHTML(article.body ?? ""),
            formattedBody: getFormattedTextFromHTML(article.body ?? ""),
          };
        })
      : [];
  },
};

export default zendesk;
