const path = require("path");
const sprintf = require("i18next-sprintf-postprocessor");
const ns = require("./public/locales/namespaces");

/**
 * We cast these to `const`. This is similar to doing `as const` in ts.
 * {@link https://www.typescriptlang.org/docs/handbook/jsdoc-supported-types.html#casts}
 */
const locales = /** @type {const} */ (["en", "nl", "de", "fr"]);
const defaultLocale = /** @type {const} */ ("en");

module.exports = /** @type {const} */ ({
  i18n: {
    defaultLocale,
    locales,
  },
  postProcess: "sprintf",
  returnNull: false,
  fallbackLng: false,
  // debug: true,
  ns,
  localePath: path.resolve("./public/locales"),
  serializeConfig: false,
  use: [sprintf],
  interpolation: {
    format: (value, format, lng) => {
      if (
        format === "currency" &&
        typeof value?.amount === "number" &&
        value?.currency
      ) {
        return new Intl.NumberFormat(lng, {
          style: "currency",
          minimumFractionDigits: Number.isInteger(value?.amount) ? 0 : 2,
          currency: value?.currency,
        }).format(value?.amount);
      }
      return value;
    },
  },
});
