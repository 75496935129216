export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;

export const ENABLE_VWO = process.env.NEXT_PUBLIC_ENABLE_VWO;

export const OPTIMIZE_ID = process.env.NEXT_PUBLIC_OPTIMIZE_ID;

export const ENABLE_WIQHIT = process.env.NEXT_PUBLIC_ENABLE_WIQHIT;

export const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;

export const ALGOLIA_API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY;

export const ENABLE_NEWRELIC = process.env.NEXT_PUBLIC_ENABLE_NEWRELIC;

export const ENABLE_TRACEDOCK = process.env.NEXT_PUBLIC_ENABLE_TRACEDOCK;

export const CANONICAL_DOMAIN = process.env.NEXT_PUBLIC_CANONICAL_DOMAIN;

export const KLAVIYO_COMPANY_ID = process.env.NEXT_PUBLIC_KLAVIYO_COMPANY_ID;

export const STRIPE_PUBLISHABLE_KEY =
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;

export const BOOST_AD_SERVICE_KEY =
  process.env.NEXT_PUBLIC_BOOST_AD_SERVICE_KEY;

export const NEXT_PUBLIC_GOOGLE_MAPS_PLACE_ID =
  process.env.NEXT_PUBLIC_GOOGLE_MAPS_PLACE_ID;

/**
 * According to official Node.js documentation at https://nodejs.org/api/process.html#process_process_env
 * - Assigning a property on process.env will implicitly convert the value to a string. This behavior is deprecated.
 */
export const GOOGLE_CLIENT_ID =
  process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID?.trim?.() ?? "";

export const FACEBOOK_APP_ID =
  process.env.NEXT_PUBLIC_FACEBOOK_APP_ID?.trim?.() ?? "";

export const DASHBOARD_ENDPOINT =
  process.env.NEXT_PUBLIC_DASHBOARD_ENDPOINT ||
  "https://dashboard.production.whoppah.com";

export const GRAPHQL_ENDPOINT =
  process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT ||
  "https://gateway.production.whoppah.com";

export const GRAPHQL_SUBSCRIPTIONS_ENDPOINT =
  process.env.NEXT_PUBLIC_GRAPHQL_SUBSCRIPTIONS_ENDPOINT ||
  "ws://gateway.production.whoppah.com";

export const NEXT_PUBLIC_GOOGLE_MAPS_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY?.trim?.() ?? "";

export const BRAZE_API_KEY =
  process.env.NEXT_PUBLIC_BRAZE_API_KEY?.trim?.() || "";

export const BRAZE_SDK_ENDPOINT =
  process.env.NEXT_PUBLIC_BRAZE_SDK_ENDPOINT?.trim?.() || "";

export const APPLE_CLIENT_ID = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID || "";
export const APPLE_RETURN_URL = process.env.NEXT_PUBLIC_APPLE_RETURN_URL || "";

export const STATSIG_CLIENT_KEY = process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY;
export const STATSIG_SERVER_KEY = process.env.NEXT_PUBLIC_STATSIG_SERVER_KEY;
export const STATSIG_ENVIRONMENT =
  process.env.NEXT_PUBLIC_STATSIG_ENVIRONMENT || "development";

export const STRAPI_ENDPOINT =
  process.env.NEXT_PUBLIC_STRAPI_ENDPOINT || "https://strapi.whoppah.com";

export const MEGA_MENU_SLUG = process.env.MEGA_MENU_SLUG || "mega-menu";
export const MEGA_MENU_MOBILE_SLUG =
  process.env.MEGA_MENU_MOBILE_SLUG || "mobile";
