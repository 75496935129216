import abaAwardImage from "../../assets/footer/aba-award.png";
import cioAwardImage from "../../assets/footer/cio-award.png";
import kpmgAwardImage from "../../assets/footer/kpmg-award.png";
import mtAwardImage from "../../assets/footer/mt-award.png";

import {
  CHAT_ROUTE,
  ACCOUNT_FAVORITES_ROUTE,
  ACCOUNT_SEARCHES_ROUTE,
  ACCOUNT_ADS_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  BRANDS_ROUTE,
  DECORATION_ROUTE,
  ART_ROUTE,
  COFFEE_TABLE_BOOKS_ROUTE,
  LIGHTING_ROUTE,
  FURNITURE_ROUTE,
  COLLECTIONS_ROUTE,
  HIW_SELLING_ROUTE,
  HIW_BUYING_ROUTE,
  CURATION_ROUTE,
  ABOUT_ROUTE,
  REVIEWS_ROUTE,
  CONTACT_US_ROUTE,
  BUSINESS_TO_BUSINESS_ROUTE,
  SHIPPING_ROUTE,
  PAYMENT_ROUTE,
  TERMS_ROUTE,
  PRIVACY_ROUTE,
  IMPRESSUM_ROUTE,
  ACCOUNT_SALES_ROUTE,
  ACCOUNT_PURCHASES_ROUTE,
} from "@/common/static/routes.static";

import { LinkEntry } from "@/common/contracts/footer.contracts";

import localizeHref from "../../../lib/multilingual/localizeHref";
import zendesk from "../../../lib/zendesk";
import { locales } from "@/i18n/config";
import { Locale } from "next/router";

export const INSTAGRAM_URL = "https://www.instagram.com/whoppahsocial/";
export const LINKEDIN_URL = "https://www.linkedin.com/company/whoppah/";
export const FACEBOOK_URL = "https://www.facebook.com/WhoppahSocial";

export const AWARDS = [
  { title: "cio", image: cioAwardImage },
  { title: "aba", image: abaAwardImage },
  { title: "kmpg", image: kpmgAwardImage },
  { title: "mt-sprout", image: mtAwardImage },
];

type LinkValue = {
  title: string;
  links: LinkEntry[];
};

export const LINKS_MAP: LinkValue[] = [
  {
    title: "footer:footer-user",
    links: [
      {
        label: "footer:footer-my-account",
        path: ACCOUNT_SETTINGS_ROUTE,
      },
      {
        label: "footer:footer-user-sales",
        path: ACCOUNT_SALES_ROUTE,
      },
      {
        label: "footer:footer-user-purchases",
        path: ACCOUNT_PURCHASES_ROUTE,
      },
      {
        label: "footer:footer-your-ads",
        path: ACCOUNT_ADS_ROUTE,
      },
      {
        label: "footer:footer-saved-searches",
        path: ACCOUNT_SEARCHES_ROUTE,
      },
      {
        label: "footer:footer-chats",
        path: CHAT_ROUTE,
      },
      {
        label: "footer:footer-favorites",
        path: ACCOUNT_FAVORITES_ROUTE,
      },
    ],
  },
  {
    title: "footer:footer-categories",
    links: [
      {
        label: "footer:footer-category-furniture",
        path: FURNITURE_ROUTE,
      },
      {
        label: "footer:footer-category-lighting",
        path: LIGHTING_ROUTE,
      },
      {
        label: "footer:footer-category-art",
        path: ART_ROUTE,
      },
      {
        label: "footer:footer-category-decoration",
        path: DECORATION_ROUTE,
      },
      {
        label: "footer:footer-category-coffee-books",
        path: COFFEE_TABLE_BOOKS_ROUTE,
      },
      {
        label: "footer:footer-category-brands",
        path: locales.reduce(
          (acc, locale) => {
            acc[locale] = localizeHref(BRANDS_ROUTE, "en", locale);
            return acc;
          },
          {} as Record<Locale, string>
        ),
      },
      {
        label: "footer:footer-category-collections",
        path: COLLECTIONS_ROUTE,
      },
    ],
  },
  {
    title: "footer:footer-buy-sell",
    links: [
      {
        label: "footer:footer-how-it-works-selling",
        path: HIW_SELLING_ROUTE,
      },
      {
        label: "footer:footer-how-it-works-buying",
        path: HIW_BUYING_ROUTE,
      },
      {
        label: "footer:footer-business-to-business",
        path: BUSINESS_TO_BUSINESS_ROUTE,
      },
      {
        label: "footer:footer-curation-rules",
        path: CURATION_ROUTE,
      },

      {
        label: "footer:footer-payment",
        path: PAYMENT_ROUTE,
      },
      {
        label: "footer:footer-shipping",
        path: SHIPPING_ROUTE,
      },

      {
        label: "footer:footer-bidding",
        path: HIW_BUYING_ROUTE,
      },
    ],
  },
  {
    title: "footer:footer-whoppah",
    links: [
      {
        label: "footer:footer-about-us",
        path: ABOUT_ROUTE,
      },
      {
        label: "footer:footer-reviews",
        path: REVIEWS_ROUTE,
      },
      {
        label: "footer:footer-faq",
        path: locales.reduce(
          (acc, locale) => {
            acc[locale] = zendesk.getUrl(locale);
            return acc;
          },
          {} as Record<Locale, string>
        ),
      },
      {
        label: "footer:footer-contact-us",
        path: CONTACT_US_ROUTE,
      },
      {
        label: "footer:footer-privacy-and-cookies",
        path: PRIVACY_ROUTE,
        externalLink: true,
      },
      {
        label: "footer:footer-terms-and-conditions",
        path: TERMS_ROUTE,
        externalLink: true,
      },
      {
        label: "footer:footer-impressum",
        path: IMPRESSUM_ROUTE,
        externalLink: true,
        excludeOnLocals: ["en", "nl", "fr"],
      },
    ],
  },
];
